<template>
  <div class="wrapper">

    <Menu></Menu>

    <Slider></Slider>

    <div class="body mt-1">
      <Highlights></Highlights>
<!--      <Games v-bind:sport="sport_id" live="true" v-show="current_sub_page !== 'countries' "></Games>-->
      <Alllive v-bind:sport="sport_id" upcoming="0" leo="true" v-show="current_sub_page !== 'countries' "></Alllive>

      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="home"></bottom-navigation>

    </div>

  </div>
</template>

<script>
import Highlights from './Highlights'
import Slider from './Slider'
import Alllive from './Alllive'
import BottomFooter from './BottomFooter'
import Menu from './Menu'
import BottomNavigation from './BottomNavigation'

export default {
  name: 'Live',
  components: {
    Menu,
    Slider,
    Highlights,
    Alllive,
    BottomFooter,
    BottomNavigation
  },
  data: function (){

    return {
      sub_page: '',
    }

  },
  mounted() {

    this.$store.dispatch("setCurrentPage","live");
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour",-1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID",0);

    // reset game filter
    //this.$store.dispatch("resetAllGames");
    this.reloadProfile();

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;
    }
  },
  methods: {

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    }
  }
}
</script>